<template>
  <v-container
    fill-height
    fluid
    grid-list-xl
  >
    <v-layout
      justify-center
      wrap
    >
      <v-flex
        md12
      >
        <material-card
          color="green"
          :title="'Список товаров в заказе '+order.paymentOrderId"
          :text="user.name + ' ' + user.email"
        >
          <v-data-table
            :headers="headers"
            :items="items"
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text-success text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <td>{{ item.name }}</td>
              <td>{{ item.amount }}</td>
              <td>{{ item.price }}</td>
              <td>{{ item.price * item.amount }}</td>
              <td>{{ item.hasReceived ? 'Да' : 'Нет' }}</td>
            </template>
          </v-data-table>
        </material-card>
      </v-flex>
      <!-- <v-flex
        md12
      >
        <material-card
          color="green"
          flat
          full-width
          title="Table on Plain Background"
          text="Here is a subtitle for this table"
        >
          <v-data-table
            :headers="headers"
            :items="items.slice(0, 7)"
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <td>{{ item.name }}</td>
              <td>{{ item.country }}</td>
              <td>{{ item.city }}</td>
              <td class="text-xs-right">{{ item.salary }}</td>
            </template>
          </v-data-table>
        </material-card>
      </v-flex> -->
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    headers: [
      {
        sortable: false,
        text: 'Товар',
        value: 'name'
      },
      {
        sortable: false,
        text: 'Количество',
        value: 'amount'
      },
      {
        sortable: false,
        text: 'Цена',
        value: 'price'
      },
      {
        sortable: false,
        text: 'Сумма',
        value: 'amount * price'
      },
      {
        sortable: false,
        text: 'Получен',
        value: 'hasReceived'
      }
    ],
    items: [
      
    ],
    user: {},
    order: {}
  }),
  created (){
    this.id = this.$route.params.id;
  },
  mounted () {
    this.getOrder()
    this.getOrderItems()
  },
  methods: {
    getOrder (){
      this.$http.get('/statistics/orders/'+this.id)
        .then(response => {
          this.order = response.data
          this.getOrderUser(this.order.userId)
        })
    },
    getOrderItems(){
      this.$http.get('/statistics/orders/'+this.id + '/items')
        .then(response => {
          this.items = response.data
          
        })
    },
    getOrderUser(userId){
      this.$http.get('/vendingprofiles/' + userId)
        .then(response => {
          this.user = response.data
        })
    }
  }
}
</script>
